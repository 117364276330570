import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { Colors } from '@/Theme/Variables'
import { useTheme } from '@/Hooks'

interface AdvanceRatingsProps {
  title: string
  rating: number
}

const AdvanceRatings = ({ title, rating }: AdvanceRatingsProps) => {
  const { Fonts } = useTheme()
  const percentage = (rating / 5) * 100

  return (
    <View style={styles.container}>
      <Text style={[Fonts.size14, Fonts.weight400, styles.title]}>{title}</Text>

      <View style={styles.progressContainer}>
        <View style={[styles.progressBar, { width: `${percentage}%` }]} />
      </View>

      <Text style={[Fonts.size14, Fonts.weight400, styles.rating]}>
        {rating ? rating?.toFixed(1) : '-'}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 16,
  },
  title: {
    flex: 2,
    color: Colors.fontText.light.primary3,
  },
  progressContainer: {
    flex: 10,
    height: 4,
    backgroundColor: Colors.background.top,
    borderRadius: 16,
    marginHorizontal: 12,
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#EECD96',
    borderRadius: 16,
  },
  rating: {
    flex: 1,
    color: Colors.fontText.light.primary3,
    textAlign: 'right',
  },
})

export default AdvanceRatings
