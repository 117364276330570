import React, { useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header, WarningSignComponent } from '@/Components'
import { RootState } from '@/Store'
import { logApi } from '@/Services/logApi'
import { globalActions } from '@/Store/Global'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { MainPagesEnum } from '@/Navigators/Main'
import { PagesEnum } from '@/Navigators/Application'
import historyApi from '@/Services/modules/history'

import SliderCard from './components/sliderCard'

enum ScanLogStatus {
  FirstTimeSuccess = 0,
  SuccessButReScan = 1,
  ReportScanResult = 4,
  SuggestionSuccess = 7,
}

const ScanResultContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.theme)

  const [hideHeader, setHideHeader] = useState<boolean>(false)
  // 使用者拍攝的照片
  // const [imageURI] = useState(route?.params?.imageURI || '')
  const [scanData] = useState(route?.params?.scanData || [])
  const [sliderIndex, setSliderIndex] = useState(0)

  // 紀錄圖片搜尋 log
  const [lazyGetImageScanLogRequest] = logApi.useLazyGetImageScanLogQuery()

  // 更改紀錄的酒資料（點擊 suggestion spirit）
  const [lazyPatchRecognitionHistoriesRequest] =
    historyApi.usePatchRecognitionHistoriesMutation()

  const scanDataTop5 = scanData?.data?.slice(0, 5) || []
  const sliderNumber = scanDataTop5.length

  const styles = getStyle()

  const handleHideHeader = (event: any) => {
    const { y } = event.nativeEvent.contentOffset
    if (y > 50 && !hideHeader) {
      setHideHeader(true)
    }
    if (y < 50 && hideHeader) {
      setHideHeader(false)
    }
  }

  // 如果是 slider index === 0 即是最佳匹配，反之是建議匹配
  const handleCheckSpirit = (id: number, title: string) => {
    if (sliderIndex === 0) {
      lazyGetImageScanLogRequest({
        request_id: scanData.requestId,
        status: ScanLogStatus.FirstTimeSuccess as number,
      })
    } else {
      // 紀錄建議匹配的酒資料
      lazyGetImageScanLogRequest({
        request_id: scanData.requestId,
        status: ScanLogStatus.SuggestionSuccess as number,
      })
      // 需覆蓋辨識紀錄的資料
      lazyPatchRecognitionHistoriesRequest({
        historyId: scanData.historyId,
        spiritId: id.toString(),
      })
    }

    navigation.navigate(PagesEnum.ProductDetail, {
      id,
      lang: language,
      name: title,
    })
  }

  const handleReOpenCamera = () => {
    lazyGetImageScanLogRequest({
      request_id: scanData.requestId,
      status: ScanLogStatus.SuccessButReScan,
    })
    handleGoBack()
  }

  const handleGoBack = () => {
    navigation.navigate(MainPagesEnum.Camera)
  }
  const handleClickSlider = (type: 'prev' | 'next') => {
    if (type === 'prev' && sliderIndex > 0) {
      setSliderIndex(sliderIndex - 1)
    } else if (type === 'next' && sliderIndex < sliderNumber - 1) {
      setSliderIndex(sliderIndex + 1)
    }
  }

  const handleConfirmReport = () => {
    lazyGetImageScanLogRequest({
      request_id: scanData.requestId,
      status: ScanLogStatus.ReportScanResult,
    })
    dispatch(globalActions.closeBottomDialog())
    navigation.navigate(AuthPagesEnum.ReportSpiritContainer, {
      historyId: scanData.historyId,
    })
  }

  // 確認回報辨識錯誤
  const openConfirmReportDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '確認回報？',
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[Fonts.weight500, Fonts.size14, { color: Colors.white }]}
              >
                收到您的回報後，我們將檢查酒資料並盡快修正。此回報機制不會蒐集用戶個人資訊。
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    backgroundColor: Colors.primary,
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={handleConfirmReport}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.black },
                  ]}
                >
                  確認回報
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title="辨識結果"
        leftIcon={
          <Image
            style={[Layout.iconSize24]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
      />
      <ScrollView
        style={[Layout.fill, Layout.fullHeight]}
        onScroll={handleHideHeader}
      >
        <FadeInView duration={250} style={styles.carouselContainer}>
          <Carousel
            centerMode
            centerSlidePercentage={80}
            showArrows={false}
            showIndicators={false}
            swipeable={true}
            emulateTouch={true}
            selectedItem={sliderIndex}
            onChange={index => setSliderIndex(index)}
            width={380}
            showStatus={false}
          >
            {scanDataTop5?.map((item: any, index: number) => {
              return (
                <SliderCard
                  key={item?.imageUrl}
                  image={item?.imageUrl}
                  brand={item?.brand}
                  title={item?.title}
                  rating={item?.ratings?.average}
                  ratingCount={item?.ratings?.count}
                  bestMatch={index === 0}
                  onPress={() => handleCheckSpirit(item?.id, item?.title)}
                  isActive={index === sliderIndex}
                />
              )
            })}
          </Carousel>
        </FadeInView>
        <View style={[styles.sliderDotsContainer]}>
          <TouchableOpacity
            style={[
              styles.sliderArrow,
              { opacity: sliderIndex === 0 ? 0.5 : 1 },
            ]}
            disabled={sliderIndex === 0}
            onPress={() => handleClickSlider('prev')}
          >
            <Image
              style={[Layout.iconSize40]}
              source={Images.arrowLeft}
              resizeMode="cover"
            />
          </TouchableOpacity>
          {new Array(sliderNumber).fill(0).map((_: number, index: number) => {
            const isActive = index === sliderIndex
            return (
              <View
                style={[isActive ? styles.sliderDotActive : styles.sliderDot]}
              />
            )
          })}
          <TouchableOpacity
            style={[
              styles.sliderArrow,
              { opacity: sliderIndex === sliderNumber - 1 ? 0.5 : 1 },
            ]}
            disabled={sliderIndex === sliderNumber - 1}
            onPress={() => handleClickSlider('next')}
          >
            <Image
              style={[Layout.iconSize40, { transform: [{ rotate: '180deg' }] }]}
              source={Images.arrowLeft}
              resizeMode="cover"
            />
          </TouchableOpacity>
        </View>
        {/* 下方 CTA */}
        <View
          style={[
            styles.bottomContainer,
            Layout.colCenter,
            Gutters.regularTPadding,
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
              Gutters.regularHPadding,
              Gutters.tinyBPadding,
            ]}
          >
            <TouchableOpacity
              style={[styles.bottomActionButton]}
              onPress={handleReOpenCamera}
            >
              <Image
                source={Images.camera_white}
                resizeMode="contain"
                style={[Layout.iconSize24]}
              />
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                重新拍照
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.bottomActionButton]}
              onPress={openConfirmReportDialog}
            >
              <Image
                source={Images.info}
                resizeMode="contain"
                style={[Layout.iconSize24]}
              />
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                回報找不到
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.warningSignContainer]}>
            <WarningSignComponent />
          </View>
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    carouselContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 24,
      paddingLeft: 36,
      paddingTop: 32,
      gap: 24,
    },
    bottomContainer: {
      width: '100%',
      marginTop: 'auto',
    },
    warningSignContainer: {
      width: '100%',
      marginTop: 'auto',
    },
    sliderArrow: {
      height: '100%',
    },
    sliderDotsContainer: {
      width: '100%',
      height: 40,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
    },
    sliderDot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: '#545454',
    },
    sliderDotActive: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: Colors.primary,
    },
    bottomActionButton: {
      width: '48%',
      height: 48,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 4,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.background.top,
    },
  })

export default ScanResultContainer
