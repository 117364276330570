import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native'

import { useFlavorData, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { capitalizeFirstLetter } from '@/Util/global'

interface FlavorItemsProps {
  flavors: string[]
  rowWrap?: boolean
  openFlavors?: boolean
}

interface FlavorDataProps {
  parentFlavor: string
  childFlavor: string[]
}

const FlavorItems = ({
  flavors,
  rowWrap = false,
  openFlavors = false,
}: FlavorItemsProps) => {
  const { t } = useTranslation()
  const { Layout, Gutters, Fonts } = useTheme()
  const {
    handleGetFlavorParent,
    handleGetFlavorColorByParent,
    isSuccess: flavorIsSuccess,
  } = useFlavorData()

  const [flavorData, setFlavorData] = useState<Array<FlavorDataProps>>([])
  const [flavorDataSliceNumber, setFlavorDataSliceNumber] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [flavorItemWidth, setFlavorItemWidth] = useState<{
    totalWidth: number
    flavorItemWidth: number[]
  }>({
    totalWidth: 0,
    flavorItemWidth: [],
  })
  const [showMoreFlavorFlag, setShowMoreFlavorFlag] = useState(false)
  const itemRefs = useRef<View[]>([])

  const onLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout
    setContainerWidth(width)
  }

  useEffect(() => {
    if (flavors.length && flavorIsSuccess) {
      const data: FlavorDataProps[] = []
      const dataMap = new Map()
      flavors.forEach(flavor => {
        const parentName = handleGetFlavorParent(flavor).name
        if (dataMap.has(parentName)) {
          dataMap.get(parentName).push(flavor)
        } else {
          dataMap.set(parentName, [flavor])
        }
      })

      dataMap.forEach((value, key) => {
        data.push({
          parentFlavor: key,
          childFlavor: value,
        })
      })
      setFlavorData(data)
      setFlavorDataSliceNumber(data.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flavors, flavorIsSuccess])

  // 偵測 flavor item 寬度
  useEffect(() => {
    if (containerWidth > 0 && flavorData.length > 0 && !rowWrap) {
      flavorData.forEach((flavor, index) => {
        const itemView = itemRefs.current[index]

        if (itemView) {
          itemView.measure((x, y, width) => {
            setFlavorItemWidth(prev => ({
              ...prev,
              flavorItemWidth: [...prev.flavorItemWidth, width],
              totalWidth: prev.totalWidth + width,
            }))
          })
        }
      })
    }
  }, [containerWidth, flavorData, itemRefs, rowWrap])

  useEffect(() => {
    if (flavorItemWidth.totalWidth > containerWidth) {
      setShowMoreFlavorFlag(true)
      let sum = 0
      let index = 0
      for (
        let item = 0;
        item < flavorItemWidth.flavorItemWidth.length;
        item++
      ) {
        sum += flavorItemWidth.flavorItemWidth[item]
        if (sum > containerWidth) {
          index = item
          break
        }
      }
      setFlavorDataSliceNumber(index)
    }
  }, [
    containerWidth,
    flavorDataSliceNumber,
    flavorItemWidth.flavorItemWidth,
    flavorItemWidth.totalWidth,
  ])

  const convertFlavorChildren = (children: string[], limit: number) => {
    const flavorChildren = openFlavors ? children : children.slice(0, limit)

    return (
      <View style={[Layout.row]}>
        {flavorChildren.map((childName, index) => {
          const lastItem = index === flavorChildren.length - 1
          const more = children.length > 3 && !openFlavors
          const lastItemEndText = lastItem ? `${more ? '...' : ''}` : '| '
          return (
            <Text
              key={childName}
              style={[
                Fonts.weight400,
                Fonts.size12,
                { color: Colors.fontText.light.primary3 },
              ]}
            >
              {`${capitalizeFirstLetter(
                t(`flavor-wheel:${childName}`),
              )} ${lastItemEndText}`}
            </Text>
          )
        })}
      </View>
    )
  }

  if (!flavorIsSuccess || !flavors || flavorData.length === 0) {
    return null
  }

  return (
    <View
      style={[
        styles.flavorsContainer,
        rowWrap
          ? styles.flavorsContainerRowWrap
          : styles.flavorsContainerRowNoWrap,
      ]}
      onLayout={onLayout}
    >
      {flavorData.slice(0, flavorDataSliceNumber).map((flavor, index) => {
        const color = handleGetFlavorColorByParent(flavor.parentFlavor)
        return (
          <View
            style={[styles.flavorsItem]}
            key={index}
            ref={(el: any) => (itemRefs.current[index] = el)}
          >
            <View
              style={[
                styles.flavorDot,
                Gutters.tinyRMargin,
                { backgroundColor: color },
              ]}
            />
            <View style={[Layout.alignItemsStart]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size12,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {capitalizeFirstLetter(
                  t(`flavor-wheel:${flavor.parentFlavor}`),
                )}
              </Text>
              {convertFlavorChildren(flavor.childFlavor, 3)}
            </View>
          </View>
        )
      })}
      {showMoreFlavorFlag && (
        <View style={[styles.ellipsis]}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size12,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            ...
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  flavorsContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  flavorsContainerRowWrap: {
    flexWrap: 'wrap',
  },
  flavorsContainerRowNoWrap: {
    flexWrap: 'nowrap',
  },
  flavorsItem: {
    backgroundColor: Colors.darkLight,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'center',
    borderRadius: 32,
    marginRight: 10,
    marginBottom: 8,
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flavorDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  ellipsis: {
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default FlavorItems
