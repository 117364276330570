import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  Dimensions,
} from 'react-native'
import StarRating, { StarIconProps } from 'react-native-star-rating-widget'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { launchImageLibrary } from 'react-native-image-picker'
import { List, TextInput } from 'react-native-paper'

import { useFlavorData, useShare, useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header, LoadingComponent } from '@/Components'
import spiritsApi from '@/Services/modules/spirits'
import {
  capitalizeFirstLetter,
  capitalizedSentence,
  dataUriToFile,
  getConfig,
  removeEmptyKeys,
} from '@/Util/global'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'
import { useIsFocused } from '@react-navigation/native'
import { RootState } from '@/Store'
import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import useUploadFile from '@/Hooks/useUploadFile'

import { RatingCupIcons } from './config'

const StarEmpty = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M16 1.61804L19.1167 11.2102L19.229 11.5557H19.5922H29.6781L21.5184 17.484L21.2246 17.6976L21.3368 18.0431L24.4535 27.6353L16.2939 21.7069L16 21.4934L15.7061 21.7069L7.54649 27.6353L10.6632 18.0431L10.7754 17.6976L10.4816 17.484L2.32194 11.5557H12.4078H12.771L12.8833 11.2102L16 1.61804Z"
      stroke="#D9D9D9"
    />
  </Svg>
)

const StarFull = (props: SvgProps) => (
  <Svg {...props}>
    <Path d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z" />
  </Svg>
)

// 這個是比較大且可以操作的 rating icon
export const RatingStarIcon = ({ color, size, type }: StarIconProps) => {
  if (type === 'empty') {
    return (
      <StarEmpty fill={Colors.background.surface} width={size} height={size} />
    )
  }

  return <StarFull fill={color} width={size} height={size} />
}

const ReviewDetail = ({ route, navigation }: any) => {
  const { id, mySpiritId, page, titleUrlSlug } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()
  const { uploadFile: uploadFileRequest, isLoading: uploadFileIsLoading } =
    useUploadFile()
  const { handleGetFlavorColor } = useFlavorData()
  const { language } = useSelector((state: RootState) => state.theme)
  const { spirit: spiritData } = useSelector((state: RootState) => state.spirit)

  const styles = getStyle()

  // 取得 note 資料
  const { data: noteDetail, isLoading: noteDetailIsLoading } =
    noteApi.useGetNoteDetailQuery(
      {
        id: mySpiritId,
      },
      {
        skip: !mySpiritId,
      },
    )
  // 新增或修改spirit 評論
  const [
    postMySpiritReviewRequest,
    {
      data: postMySpiritReviewData,
      isLoading: postMySpiritReviewLoading,
      isSuccess: postMySpiritReviewSuccess,
      reset: postMySpiritReviewReset,
    },
  ] = spiritsApi.usePostMyBoozeReviewMutation()

  const showSuccessView = useMemo(() => {
    return postMySpiritReviewSuccess
  }, [postMySpiritReviewSuccess])

  const handleShare = useShare({
    url: `${getConfig()?.DOMAIN}/ReviewNote?id=${
      postMySpiritReviewData?.data?.id
    }`,
    title: '',
    text: '',
    snackbarContent: '成功複製分享連結！',
  })

  // 取得下拉選單（容器）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.GLASS,
    locale: language,
  })

  // 取得下拉選單（配餐）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.PAIRING,
    locale: language,
  })

  // 新增筆記圖檔
  const [postNoteImageRequest, { isLoading: postNoteImageIsLoading }] =
    noteApi.usePostNoteImageMutation()

  // 刪除筆記圖檔
  const [deleteNoteImageRequest, { isLoading: deleteNoteImageIsLoading }] =
    noteApi.useDeleteNoteImageMutation()

  const [cupImage, setCupImage] = useState<string>(RatingCupIcons[0].icon)
  const [flavorTags, setFlavorTags] = useState<string[]>([])
  const [advanceNoteInfoExpanded, setAdvanceNoteInfoExpanded] =
    useState<boolean>(false)
  const [hasChangeImage, setHasChangeImage] = useState<boolean>(false)
  const [deleteImage, setDeleteImage] = useState<boolean>(false)

  // 選擇 rating 描述
  useEffect(() => {
    const cupIcon = RatingCupIcons.find(
      (item: any) => item.rating === Number(spiritData.rating),
    )
    setCupImage(cupIcon?.icon || '')
  }, [spiritData.rating])

  // 拿 api 資料
  useEffect(() => {
    if (noteDetail && isFocused) {
      // 查看是否有進階筆記內容
      const checkHasExpertise = (expertValue: any) => {
        let result: boolean = false
        Object.values(expertValue).forEach(value => {
          if (value) {
            if (Array.isArray(value)) {
              if (value?.length > 0) {
                result = true
              }
              return
            }
            result = true
          }
        })
        return result
      }

      const expertiseData = {
        glass: spiritData?.glass || noteDetail?.data?.expertise?.glass,
        pairing: spiritData?.pairing || noteDetail?.data?.expertise?.pairing,
        price:
          spiritData?.price || noteDetail?.data?.expertise?.price?.toString(),
        tastingVolume:
          spiritData?.tastingVolume ||
          noteDetail?.data?.expertise?.tastingVolume?.toString(),
        bottledAge:
          spiritData?.bottledAge ||
          noteDetail?.data?.expertise?.bottledAge?.toString(),
        batchNumber:
          spiritData?.batchNumber ||
          noteDetail?.data?.expertise?.batchNumber?.toString(),
        purchasePlace:
          spiritData?.purchasePlace ||
          noteDetail?.data?.expertise?.purchasePlace,
      }

      setAdvanceNoteInfoExpanded(
        checkHasExpertise(noteDetail?.data?.expertise) ||
          checkHasExpertise(expertiseData),
      )

      setFlavorTags(spiritData?.flavors || noteDetail?.data?.flavors)
      dispatch(
        spiritActions.setSpiritData({
          rating: spiritData?.rating || noteDetail?.data?.rating,
          content: spiritData?.content || noteDetail?.data?.content,
          flavors: spiritData?.flavors || noteDetail?.data?.flavors,
          imagePath: spiritData?.imagePath || noteDetail?.data?.image?.url,
          advancedRatings: {
            nose:
              spiritData?.advancedRatings?.nose ||
              noteDetail?.data?.advancedRatings?.nose,
            taste:
              spiritData?.advancedRatings?.taste ||
              noteDetail?.data?.advancedRatings?.taste,
            finish:
              spiritData?.advancedRatings?.finish ||
              noteDetail?.data?.advancedRatings?.finish,
          },
          ...expertiseData,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteDetail, spiritData?.flavors, isFocused])

  // 同步風味資料
  useEffect(() => {
    if (spiritData?.flavors) {
      setFlavorTags(spiritData?.flavors || [])
    }
  }, [spiritData?.flavors])

  const handleBackToProduct = useCallback(() => {
    const routeParams: any = {
      lang: language,
      titleUrlSlug: titleUrlSlug,
      id,
      page: page !== undefined ? page : '',
    }
    Object.keys(routeParams).forEach(key => {
      if (routeParams[key] === undefined || routeParams[key] === '') {
        delete routeParams[key]
      }
    })

    navigation.navigate(PagesEnum.ProductDetail, routeParams)
  }, [id, language, navigation, page, titleUrlSlug])

  const handleClickGoBack = useCallback(
    async (popNum: number) => {
      if (navigation.canGoBack()) {
        dispatch(spiritActions.clearSpiritData())
        navigation.pop(popNum)
        return
      }
      handleBackToProduct()
    },
    [navigation, handleBackToProduct, dispatch],
  )

  const handleRedirectWhenSubmitSuccess = () => {
    if (postMySpiritReviewSuccess) {
      // 如果沒評論過，就 go back 2 到詳情頁，如果有評論過會跳過 RatingAndCommentContainer 頁面，所以只需要 go back 1
      const needGoBack2 = navigation
        .getState()
        ?.routes?.filter(
          (routeItem: any) =>
            routeItem.name === AuthPagesEnum.RatingAndCommentContainer,
        )

      if (needGoBack2?.length) {
        handleClickGoBack(2)
      } else {
        handleClickGoBack(1)
      }
      postMySpiritReviewReset()
    }
  }

  const handleInputTextNumber = (value: string) => {
    return value.replace(/[^0-9]/, '')
  }

  // 提醒尚未儲存 dialog
  const openConfirmLeaveDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '尚未儲存',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.regularBMargin]}>
            <View
              style={[
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[Fonts.size14, Fonts.weight500, { color: Colors.white }]}
              >
                您尚有編輯過的文本沒有儲存，確定離開？您的文本將會消失。
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.snackbar.error,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={() => {
                  handleClickGoBack(1)
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.fontText.dark.primary2 },
                  ]}
                >
                  確定離開
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  { height: Height.height48 },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.bold, { color: Colors.white }]}>取消</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  const handleNeedsShowDialog = () => {
    // 確認是否變更風味、評論內容
    const changedContent =
      spiritData.content !== noteDetail?.data?.content ||
      spiritData.flavors?.join() !== noteDetail?.data?.flavors?.join() ||
      spiritData.rating !== noteDetail?.data?.rating ||
      spiritData.imagePath !== noteDetail?.data?.image?.url ||
      spiritData.tastingVolume !==
        noteDetail?.data?.expertise?.tastingVolume?.toString() ||
      spiritData.price !== noteDetail?.data?.expertise?.price?.toString() ||
      spiritData.glass !== noteDetail?.data?.expertise?.glass ||
      spiritData.pairing?.join() !==
        noteDetail?.data?.expertise?.pairing?.join()

    if (changedContent) {
      openConfirmLeaveDialog()
    } else {
      handleClickGoBack(1)
    }
  }

  const handleRedirectToMoreInfoPage = useCallback(
    (infoPage: string) => {
      const routeParams: any = {
        id,
        mySpiritId,
        page,
        titleUrlSlug,
      }
      navigation.navigate(infoPage, removeEmptyKeys(routeParams))
    },
    [id, mySpiritId, navigation, page, titleUrlSlug],
  )

  const handleRemoveFlavor = (title: string) => {
    const newSelectedFlavorList = spiritData?.flavors?.filter(
      (item: string) => item !== title,
    )
    setFlavorTags(newSelectedFlavorList || [])
    dispatch(
      spiritActions.setSpiritData({
        flavors: newSelectedFlavorList,
      }),
    )
  }

  const handleRemovePairing = (pairing: string) => {
    const newSelectedPairing = spiritData?.pairing?.filter(
      (item: string) => item !== pairing,
    )
    dispatch(
      spiritActions.setSpiritData({
        pairing: newSelectedPairing,
      }),
    )
  }

  const handleSubmitReview = async () => {
    const payload = {
      id: id.toString(),
      rating: spiritData?.rating?.toFixed(1),
      content: spiritData.content || noteDetail?.data?.content || '',
      flavors: spiritData.flavors || noteDetail?.data?.flavors || [],
      imagePath: hasChangeImage ? spiritData?.imagePath : null,
      expertise: {
        tastingVolume: spiritData?.tastingVolume,
        price: spiritData?.price,
        glass: spiritData?.glass,
        pairing: spiritData?.pairing,
        bottledAge: spiritData?.bottledAge,
        batchNumber: spiritData?.batchNumber,
        purchasePlace: spiritData?.purchasePlace,
      },
      advancedRatings: {
        nose: spiritData.advancedRatings.nose,
        taste: spiritData.advancedRatings.taste,
        finish: spiritData.advancedRatings.finish,
      },
    }

    // 使用者有更新圖片
    if (
      payload?.imagePath &&
      payload.imagePath !== noteDetail?.data?.image?.url &&
      spiritData.imageFile &&
      hasChangeImage
    ) {
      const mediaPath = (await uploadFileRequest(spiritData.imageFile)) || ''

      const noteResponse = await postMySpiritReviewRequest(
        removeEmptyKeys(payload),
      ).unwrap()

      postNoteImageRequest({
        id: noteResponse?.data?.id,
        path: mediaPath,
      })
      return
    }

    // 使用者將圖片移除
    if (
      payload?.imagePath === null &&
      noteDetail?.data?.image?.url &&
      deleteImage
    ) {
      const noteResponse = await postMySpiritReviewRequest(
        removeEmptyKeys(payload),
      ).unwrap()

      deleteNoteImageRequest({
        id: noteResponse?.data?.id,
      })
      return
    }

    postMySpiritReviewRequest(removeEmptyKeys(payload))
  }

  // 開啟相簿
  const handleOpenGallery = async () => {
    const result = await launchImageLibrary({
      mediaType: 'photo',
      selectionLimit: 1,
    })

    if (result?.assets) {
      // 防止使用者上傳超過 5mb 的檔案
      const file = dataUriToFile(result?.assets[0]?.uri || '', mySpiritId)
      const fileSizeInMB = file?.size / 1024 / 1024
      if (fileSizeInMB >= 5) {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '檔案大小超過 5MB，請重新選擇照片',
            type: 'default',
          }),
        )
        return
      }

      const dataURI = result?.assets[0]?.uri
      setHasChangeImage(true)
      dispatch(
        spiritActions.setSpiritData({
          imagePath: dataURI,
          imageFile: file,
        }),
      )
    }
  }

  const renderFlavorTags = ({ item }: any) => {
    const color = handleGetFlavorColor(item)
    return (
      <View
        key={item.toString()}
        style={[
          Layout.row,
          Layout.rowCenter,
          Layout.justifyContentBetween,
          styles.flavorItem,
        ]}
      >
        <View
          style={[
            Gutters.miniRMargin,
            styles.flavorDot,
            { backgroundColor: color },
          ]}
        />
        <Text
          style={[
            Gutters.miniRMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >{`${capitalizeFirstLetter(t(`flavor-wheel:${item}`))}`}</Text>
        <TouchableOpacity onPress={() => handleRemoveFlavor(item)}>
          <Image
            source={Images.close}
            resizeMode="contain"
            style={[Layout.iconSize14]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const renderPairingTags = ({ item }: any) => {
    return (
      <View
        key={item.toString()}
        style={[
          Layout.row,
          Layout.rowCenter,
          Layout.justifyContentBetween,
          styles.flavorItem,
        ]}
      >
        <Text
          style={[
            Gutters.miniRMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {t(`pairing:${item}`)}
        </Text>
        <TouchableOpacity onPress={() => handleRemovePairing(item)}>
          <Image
            source={Images.close}
            resizeMode="contain"
            style={[Layout.iconSize14]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  // 評分區塊
  const renderRating = () => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Gutters.tinyBMargin,
          Gutters.regularVPadding,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <View style={[Layout.fullWidth, Gutters.smallBMargin]}>
          <Text
            style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
          >
            整體評分
          </Text>
        </View>
        <View
          style={[
            Layout.row,
            Layout.justifyContentBetween,
            {
              marginBottom: 32,
            },
          ]}
        >
          <StarRating
            rating={spiritData.rating || 0}
            starSize={38}
            color="#EECD96"
            emptyColor="#D9D9D9"
            enableHalfStar={false}
            StarIconComponent={RatingStarIcon}
            onChange={value => {
              dispatch(
                spiritActions.setSpiritData({
                  rating: value,
                }),
              )
            }}
            style={styles.starRatingContainer}
            starStyle={styles.starStyle}
            animationConfig={{
              scale: 1,
            }}
          />
          <Image
            style={styles.cupImageIcon}
            // @ts-ignore
            source={Images[cupImage]}
            resizeMode="cover"
          />
        </View>
        <View
          style={[
            Layout.row,
            Layout.justifyContentBetween,
            Layout.alignItemsCenter,
            Gutters.regularBMargin,
          ]}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            香味
          </Text>
          <StarRating
            rating={spiritData.advancedRatings.nose || 0}
            starSize={38}
            color="#EECD96"
            emptyColor="#D9D9D9"
            enableHalfStar={false}
            StarIconComponent={RatingStarIcon}
            onChange={value => {
              dispatch(
                spiritActions.setSpiritData({
                  advancedRatings: {
                    ...spiritData.advancedRatings,
                    nose: value,
                  },
                }),
              )
            }}
            style={styles.starRatingContainer}
            starStyle={styles.starStyle}
            animationConfig={{
              scale: 1,
            }}
          />
        </View>
        <View
          style={[
            Layout.row,
            Layout.justifyContentBetween,
            Layout.alignItemsCenter,
            Gutters.regularBMargin,
          ]}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            風味
          </Text>
          <StarRating
            rating={spiritData.advancedRatings.taste || 0}
            starSize={38}
            color="#EECD96"
            emptyColor="#D9D9D9"
            enableHalfStar={false}
            StarIconComponent={RatingStarIcon}
            onChange={value => {
              dispatch(
                spiritActions.setSpiritData({
                  advancedRatings: {
                    ...spiritData.advancedRatings,
                    taste: value,
                  },
                }),
              )
            }}
            style={styles.starRatingContainer}
            starStyle={styles.starStyle}
            animationConfig={{
              scale: 1,
            }}
          />
        </View>
        <View
          style={[
            Layout.row,
            Layout.justifyContentBetween,
            Layout.alignItemsCenter,
            Gutters.regularBMargin,
          ]}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            尾韻
          </Text>
          <StarRating
            rating={spiritData.advancedRatings.finish || 0}
            starSize={38}
            color="#EECD96"
            emptyColor="#D9D9D9"
            enableHalfStar={false}
            StarIconComponent={RatingStarIcon}
            onChange={value => {
              dispatch(
                spiritActions.setSpiritData({
                  advancedRatings: {
                    ...spiritData.advancedRatings,
                    finish: value,
                  },
                }),
              )
            }}
            style={styles.starRatingContainer}
            starStyle={styles.starStyle}
            animationConfig={{
              scale: 1,
            }}
          />
        </View>
      </View>
    )
  }

  // 風味區塊
  const renderFlavors = () => {
    return (
      <TouchableOpacity
        style={[
          Layout.fullWidth,
          Gutters.tinyBMargin,
          Gutters.regularTPadding,
          Gutters.regularHPadding,
          // @ts-ignore
          !!flavorTags?.length || Gutters.regularBPadding,
          { backgroundColor: Colors.background.surface },
        ]}
        onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.Flavor)}
      >
        <View
          style={[
            Layout.fullWidth,
            Gutters.smallBMargin,
            Layout.row,
            Layout.justifyContentBetween,
          ]}
        >
          <Text
            style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
          >
            風味標記
          </Text>
          <TouchableOpacity
            onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.Flavor)}
          >
            <Image
              source={Images.review_detail_page_arrow}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          </TouchableOpacity>
        </View>
        <View>
          {flavorTags?.length ? (
            <View style={[Layout.row, Layout.flexWrap]}>
              {flavorTags.map(item => renderFlavorTags({ item }))}
            </View>
          ) : (
            <Text
              style={[Fonts.weight400, Fonts.size14, styles.emptyTextColor]}
            >
              尚無紀錄
            </Text>
          )}
        </View>
      </TouchableOpacity>
    )
  }

  // 評論區塊
  const renderComment = () => {
    return (
      <TouchableOpacity
        style={[
          Layout.fullWidth,
          Gutters.tinyBMargin,
          Gutters.regularVPadding,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
        onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.CommentModal)}
      >
        <View
          style={[
            Layout.fullWidth,
            Gutters.smallBMargin,
            Layout.row,
            Layout.justifyContentBetween,
          ]}
        >
          <Text
            style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
          >
            文字筆記
          </Text>
          <TouchableOpacity
            onPress={() =>
              handleRedirectToMoreInfoPage(AuthPagesEnum.CommentModal)
            }
          >
            <Image
              source={Images.review_detail_page_arrow}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          </TouchableOpacity>
        </View>
        <View>
          <Text style={[Fonts.weight400, Fonts.size14, styles.emptyTextColor]}>
            {spiritData?.content || '尚無紀錄'}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  // 選擇圖片區塊
  const renderUploadImage = () => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Gutters.tinyBMargin,
          Gutters.regularVPadding,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <TouchableOpacity
          style={[
            styles.photoContainer,
            Layout.alignItemsCenter,
            Layout.justifyContentCenter,
            Layout.iconSize160,
          ]}
          onPress={handleOpenGallery}
        >
          {spiritData?.imagePath ? (
            <View style={Layout.iconSize160}>
              <Image
                source={{ uri: spiritData?.imagePath || '' }}
                resizeMode="cover"
                style={Layout.iconSize160}
              />
              <TouchableOpacity
                onPress={() => {
                  dispatch(spiritActions.setSpiritData({ imagePath: null }))
                  setDeleteImage(true)
                }}
                style={{
                  position: 'absolute',
                  top: 1,
                  right: 1,
                }}
              >
                <Image
                  source={Images.close}
                  resizeMode="contain"
                  style={[Layout.iconSize24]}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <Image
              source={Images.review_detail_photo}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          )}
        </TouchableOpacity>
      </View>
    )
  }

  // 進階筆記區塊
  const renderAdvanceNoteInfo = () => {
    return (
      <List.Accordion
        titleNumberOfLines={3}
        title={
          <View
            style={[
              Layout.fullWidth,
              Gutters.regularVPadding,
              { paddingLeft: 7 },
            ]}
          >
            <View
              style={[
                Layout.row,
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.tinyBMargin,
              ]}
            >
              <Image
                style={[Layout.iconSize16, Gutters.tinyRMargin]}
                source={Images.rating_comment_cup2}
                resizeMode="cover"
              />
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  Gutters.tinyRMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                品飲細節
              </Text>
            </View>
            <View>
              <Text
                style={[Fonts.size16, Fonts.weight400, { color: '#999999' }]}
              >
                加入更多品飲細節，包含餐飲搭配、品飲方式以及購買資訊
              </Text>
            </View>
          </View>
        }
        right={({ isExpanded }) => {
          return (
            <Image
              source={Images.arrowRight}
              resizeMode="contain"
              style={[
                Layout.iconSize24,
                { transform: [{ rotate: isExpanded ? '-90deg' : '90deg' }] },
              ]}
            />
          )
        }}
        style={[styles.toggleList]}
        expanded={advanceNoteInfoExpanded}
        onPress={() => setAdvanceNoteInfoExpanded(!advanceNoteInfoExpanded)}
      >
        {/* 餐品搭配 */}
        <TouchableOpacity
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
          onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.Pairing)}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              餐飲搭配
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate(AuthPagesEnum.Pairing)}
            >
              <Image
                source={Images.review_detail_page_arrow}
                resizeMode="contain"
                style={Layout.iconSize24}
              />
            </TouchableOpacity>
          </View>
          <View>
            {spiritData?.pairing?.length ? (
              <View style={[Layout.row, Layout.flexWrap]}>
                {spiritData?.pairing.map(item => renderPairingTags({ item }))}
              </View>
            ) : (
              <Text
                style={[Fonts.weight400, Fonts.size14, styles.emptyTextColor]}
              >
                尚無紀錄
              </Text>
            )}
          </View>
        </TouchableOpacity>

        {/* 品飲容器 */}
        <TouchableOpacity
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
          onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.Glass)}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              品飲容器
            </Text>
            <TouchableOpacity
              onPress={() => handleRedirectToMoreInfoPage(AuthPagesEnum.Glass)}
            >
              <Image
                source={Images.review_detail_page_arrow}
                resizeMode="contain"
                style={Layout.iconSize24}
              />
            </TouchableOpacity>
          </View>
          <View>
            <Text
              style={[Fonts.weight400, Fonts.size14, styles.emptyTextColor]}
            >
              {spiritData?.glass
                ? capitalizedSentence(t(`glass:${spiritData?.glass}`))
                : '尚無紀錄'}
            </Text>
          </View>
        </TouchableOpacity>

        {/* 品飲量 */}
        <View
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              品飲量
            </Text>
          </View>
          <View>
            <TextInput
              value={spiritData.tastingVolume || ''}
              placeholder="輸入品飲量"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{ borderWidth: 1 }}
              activeOutlineColor={Colors.primary}
              keyboardType={'number-pad'}
              onChangeText={tastingVolume =>
                dispatch(
                  spiritActions.setSpiritData({
                    tastingVolume: handleInputTextNumber(tastingVolume),
                  }),
                )
              }
            />
            <Text style={[styles.textInputRightText]}>ml</Text>
          </View>
        </View>

        {/* 批號 */}
        <View
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              批號
            </Text>
          </View>
          <View>
            <TextInput
              value={spiritData.batchNumber || ''}
              placeholder="輸入批號"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{ borderWidth: 1 }}
              activeOutlineColor={Colors.primary}
              onChangeText={batchNumber =>
                dispatch(spiritActions.setSpiritData({ batchNumber }))
              }
            />
          </View>
        </View>

        {/* 年份 */}
        <View
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              年份
            </Text>
          </View>
          <View>
            <TextInput
              value={spiritData.bottledAge || ''}
              placeholder="輸入年份"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{ borderWidth: 1 }}
              activeOutlineColor={Colors.primary}
              keyboardType={'number-pad'}
              onChangeText={bottledAge =>
                dispatch(
                  spiritActions.setSpiritData({
                    bottledAge: handleInputTextNumber(bottledAge),
                  }),
                )
              }
            />
          </View>
        </View>

        {/* 購買價格 */}
        <View
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              購買價格
            </Text>
          </View>
          <View>
            <TextInput
              value={spiritData.price || ''}
              placeholder="輸入價格"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{ borderWidth: 1 }}
              activeOutlineColor={Colors.primary}
              keyboardType={'number-pad'}
              onChangeText={price =>
                dispatch(
                  spiritActions.setSpiritData({
                    price: handleInputTextNumber(price),
                  }),
                )
              }
            />
            <Text style={[styles.textInputRightText]}>NTD</Text>
          </View>
        </View>

        {/* 購買地 */}
        <View
          style={[
            Layout.fullWidth,
            Gutters.tinyBMargin,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              Layout.row,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[Fonts.size16, Fonts.weight700, { color: Colors.white }]}
            >
              購買地
            </Text>
          </View>
          <View>
            <TextInput
              value={spiritData.purchasePlace || ''}
              placeholder="輸入購買地"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{ borderWidth: 1 }}
              activeOutlineColor={Colors.primary}
              onChangeText={purchasePlace =>
                dispatch(spiritActions.setSpiritData({ purchasePlace }))
              }
            />
          </View>
        </View>
      </List.Accordion>
    )
  }

  // 下方 CTA 區塊
  const renderCTA = () => {
    const submitBgColor = !spiritData?.rating ? '#D9D9D9' : Colors.primary
    return (
      <View
        style={[
          Gutters.smallVPadding,
          Gutters.regularHPadding,
          Layout.fullWidth,
          styles.CTAButton,
        ]}
      >
        <TouchableOpacity
          style={[
            Layout.fullWidth,
            Layout.rowCenter,
            {
              backgroundColor: submitBgColor,
              borderRadius: BorderRadius.radius8,
              height: Height.height47,
            },
          ]}
          disabled={!spiritData?.rating}
          onPress={handleSubmitReview}
        >
          {postMySpiritReviewLoading ||
          postNoteImageIsLoading ||
          uploadFileIsLoading ||
          deleteNoteImageIsLoading ? (
            <ActivityIndicator size="large" color={Colors.white} />
          ) : (
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                {
                  color: !spiritData?.rating ? Colors.gray : Colors.primaryText,
                },
              ]}
            >
              發佈
            </Text>
          )}
        </TouchableOpacity>
      </View>
    )
  }

  const renderListLoading = () => {
    return (
      <View style={[Layout.fill]}>
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  const renderTextFiledContainer = () => {
    return (
      <>
        {postMySpiritReviewLoading && <LoadingComponent />}
        <View style={[Layout.fill, styles.paddingB200]}>
          {noteDetailIsLoading && renderListLoading()}
          {renderRating()}
          {renderFlavors()}
          {renderComment()}
          {renderUploadImage()}
          {/* 進階筆記 */}
          {renderAdvanceNoteInfo()}
        </View>
      </>
    )
  }

  const renderSubmitSuccess = () => {
    return (
      <View
        style={[Layout.colCenter, Layout.fullWidth, Gutters.regularHPadding]}
      >
        <View style={[Layout.colCenter, { paddingTop: 200, marginBottom: 48 }]}>
          <Image
            source={Images.review_detail_success_icon}
            resizeMode="contain"
            style={[Layout.iconSize80, Gutters.regularBMargin]}
          />
          <Text
            style={[
              Fonts.weight700,
              Fonts.size20,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            成功發佈內容！
          </Text>
        </View>
        <View style={[Layout.fullWidth]}>
          <TouchableOpacity
            style={[
              Layout.rowCenter,
              Layout.fullWidth,
              Gutters.regularBMargin,
              { backgroundColor: Colors.primary, borderRadius: 8, height: 48 },
            ]}
            onPress={handleRedirectWhenSubmitSuccess}
          >
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              完成
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              Layout.rowCenter,
              Layout.fullWidth,
              {
                borderColor: Colors.primary,
                borderRadius: 8,
                borderWidth: 1,
                height: 48,
              },
            ]}
            onPress={handleShare}
          >
            <Image
              source={Images.share}
              resizeMode="contain"
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[Fonts.size16, Fonts.weight500, { color: Colors.white }]}
            >
              分享我的筆記
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      {!showSuccessView && (
        <Header
          title="評分詳情"
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleNeedsShowDialog}
          rightEmptyIconWidth="50"
        />
      )}
      <ScrollView style={[{ height: Dimensions.get('window').height }]}>
        <FadeInView duration={700} style={Layout.fill}>
          {showSuccessView ? renderSubmitSuccess() : renderTextFiledContainer()}
        </FadeInView>
      </ScrollView>
      {!showSuccessView && renderCTA()}
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    paddingB200: {
      paddingBottom: 200,
    },
    emptyTextColor: {
      color: '#999999',
    },
    cupImageIcon: {
      height: 36,
      width: 32,
    },
    starStyle: {
      marginRight: 0,
    },
    starRatingContainer: {
      marginLeft: -8,
    },
    flavorItem: {
      width: 'fit-content',
      paddingVertical: 8,
      paddingHorizontal: 16,
      backgroundColor: Colors.darkLight,
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 80,
      marginRight: 8,
      marginBottom: 16,
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      borderTopColor: Colors.darkText,
      borderTopWidth: 1,
      backgroundColor: Colors.background.default,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    photoContainer: {
      width: 64,
      height: 64,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.white,
      overflow: 'hidden',
    },
    toggleList: {
      backgroundColor: Colors.background.default,
    },
    textInput: {
      backgroundColor: '#1C1C20',
    },
    textInputRightText: {
      position: 'absolute',
      right: 18,
      top: 21,
      fontSize: 16,
      color: Colors.fontText.light.primary3,
    },
  })

export default ReviewDetail
