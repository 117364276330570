import { api, prefixProxyEndpoint } from '@/Services/api'
import {
  ScanPicRequest,
  ScanPicResponse,
  GetMySpiritsResponse,
  GetMySpiritsRequest,
  GetMySingleSpiritResponse,
  PostMySpiritReviewRequest,
  GetSearchSpiritsRequest,
  Booze,
  Meta,
  GetWishlistRequest,
  GetWishlistResponse,
  PostAddOrRemoveWishlistRequest,
  GetSpiritNotesResponse,
  GetSpiritNotesRequest,
  PostMediaRequest,
  PostMediaResponse,
  PostMySpiritReviewResponse,
  GetUserWishlistDataResponse,
  GetUserWishlistDataRequest,
  PostUploadSpiritsRequest,
  GetUploadSpiritsResponse,
  GetUploadSpiritsRequest,
  SpiritSort,
} from './spirits.type'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'

export const spiritsApi: any = api.injectEndpoints({
  endpoints: build => ({
    // 新版掃瞄商品（具有 ocr image-search hybrid 功能）
    scanPic: build.mutation<ScanPicResponse, ScanPicRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/spirits:detect'),
        method: 'POST',
        body,
      }),
      // 掃描後，要去 response header 拿到 request id
      transformErrorResponse: (response: any, meta: any) => {
        return {
          message: response.data?.errors?.[0]?.message,
          requestId: meta?.response?.headers.get('x-request-id'),
          historyId: meta?.response?.headers.get('x-history-id'),
        }
      },
      transformResponse: (response: ScanPicResponse, meta: any) => {
        return {
          ...response,
          requestId: meta?.response?.headers.get('x-request-id'),
          historyId: meta?.response?.headers.get('x-history-id'),
        }
      },
    }),
    // 取得 my spirit list
    getMySpirits: build.query<
      GetMySpiritsResponse,
      Partial<GetMySpiritsRequest>
    >({
      query: params => ({
        url: prefixProxyEndpoint('/v1/my-notes'),
        method: 'GET',
        params: {
          ...params,
          perPage: 10,
        },
      }),
      providesTags: ['spirits', 'note'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          dispatch(spiritActions.setMySpiritList(result.data))
        }
      },
    }),
    // 取得單一 my spirit
    getMySingleSpirit: build.query<GetMySingleSpiritResponse, string>({
      query: id => ({
        url: prefixProxyEndpoint(`/v1/spirits/${id}`),
        method: 'GET',
      }),
      providesTags: ['spirits'],
      transformResponse: (response: { data: GetMySingleSpiritResponse }) =>
        response.data,
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // workaround for first time rating will not update the rate immediately
          dispatch(spiritActions.setAddRateLoading(false))
        }
      },
    }),
    // 新增與更新 my spirit note
    postMyBoozeReview: build.mutation<
      PostMySpiritReviewResponse,
      Partial<PostMySpiritReviewRequest>
    >({
      query: data => {
        const { id, ...body } = data
        return {
          url: prefixProxyEndpoint(`/v1/spirits/${id}/my-note:upsert`),
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(spiritsApi.util.invalidateTags(['userInfo']))
          // workaround for first time rating will not update the rate immediately
          dispatch(spiritActions.setAddRateLoading(true))
          // 新增後重置 store 資料
          dispatch(spiritActions.clearMySpiritList())
          setTimeout(() => {
            dispatch(
              spiritsApi.util.invalidateTags(['spirits', 'note', 'history']),
            )
            dispatch(spiritActions.updateMySpirit(props))
            dispatch(spiritActions.setAddRateLoading(false))
          }, 3000)
        }
      },
    }),
    // 搜尋 spirit list
    getSearchSpirits: build.query<
      GetMySpiritsResponse & { requestId: string },
      Partial<GetSearchSpiritsRequest>
    >({
      // 組 filters meta data
      // ex: /v1/spirits?filters[category]=whisky,rum&filters[country]=ireland
      query: ({ filters = {}, ...params }) => {
        let filterString = ''
        if (Object.keys(filters).length > 0) {
          Object.entries(filters).forEach(([key, value], index) => {
            if (index !== 0) {
              filterString += `&filters[${key}]=${value}`
            } else {
              filterString += `filters[${key}]=${value}`
            }
          })
        }
        return {
          url: filterString
            ? prefixProxyEndpoint('/v1/spirits?' + filterString)
            : prefixProxyEndpoint('/v1/spirits'),
          method: 'GET',
          params: {
            ...params,
            sorts: params?.sorts || SpiritSort.RANK_SCORE,
            perPage: 10,
          },
        }
      },
      providesTags: ['spirits'],
      transformResponse: (
        response: { data: Booze[]; meta: Meta },
        meta: any,
      ) => {
        // 這邊需要紀錄每筆 data 是在第幾頁以及當頁的第幾筆
        const dataList: any = []
        response.data.forEach((item: Booze, index: number) => {
          dataList.push({
            ...item,
            page: response.meta.currentPage,
            selectedRow: index,
            requestId: meta?.response?.headers.get('x-request-id'),
          })
        })
        return {
          data: dataList,
          meta: response.meta,
          requestId: meta?.response?.headers.get('x-request-id'),
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(spiritActions.setSearchList(result.data))
        }
      },
    }),
    // 取得 search meta data
    getSearchMeta: build.query<any, any>({
      // 組 filters meta data
      // ex: /v1/spirits/metadata?filters[category]=whisky,rum&filters[country]=ireland
      query: params => {
        let filterString = ''
        if (params) {
          Object.entries(params).forEach(([key, value], index) => {
            if (index !== 0) {
              filterString += `&filters[${key}]=${value}`
            } else {
              filterString += `filters[${key}]=${value}`
            }
          })
        }
        return {
          url: filterString
            ? prefixProxyEndpoint('/v1/spirits/metadata?' + filterString)
            : prefixProxyEndpoint('/v1/spirits/metadata'),
          method: 'GET',
        }
      },
    }),
    // 取得 wishlist
    getWishlist: build.query<GetWishlistResponse, GetWishlistRequest>({
      query: data => {
        const { id, ...params } = data
        return {
          url: prefixProxyEndpoint(`/v1/my-collections/${id}/spirits`),
          method: 'GET',
          params: {
            ...params,
            perPage: 10,
          },
        }
      },
      providesTags: ['spirits'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(spiritActions.setWishlist(result.data))
        }
      },
    }),
    // 新增 wishlist
    postAddWishlist: build.mutation<void, PostAddOrRemoveWishlistRequest>({
      query: data => {
        const { id, ...body } = data
        return {
          url: prefixProxyEndpoint(`/v1/my-collections/${id}:attach`),
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '成功加入願望清單',
              type: 'default',
              showIcon: false,
            }),
          )
          dispatch(spiritActions.updateMySpiritCollection(props.spiritId))
          dispatch(spiritActions.updateWishlistCollection(props.spiritId))
          dispatch(spiritActions.updateProfileUserNoteList(props.spiritId))
          dispatch(spiritActions.updateProfileUserWishlistList(props.spiritId))
          dispatch(
            spiritsApi.util.invalidateTags(['spirits', 'userInfo', 'note']),
          )
        }
      },
    }),
    // 移除 wishlist
    postRemoveWishlist: build.mutation<void, PostAddOrRemoveWishlistRequest>({
      query: data => {
        const { id, ...body } = data
        return {
          url: prefixProxyEndpoint(`/v1/my-collections/${id}:detach`),
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '已從願望清單移除',
              type: 'default',
              showIcon: false,
            }),
          )
          dispatch(spiritActions.updateMySpiritCollection(props.spiritId))
          dispatch(spiritActions.updateWishlistCollection(props.spiritId))
          dispatch(spiritActions.updateProfileUserNoteList(props.spiritId))
          dispatch(spiritActions.updateProfileUserWishlistList(props.spiritId))
          dispatch(spiritsApi.util.invalidateTags(['userInfo', 'note']))
        }
      },
    }),
    // 取得這酒的所有 review notes
    getSpiritNotes: build.query<GetSpiritNotesResponse, GetSpiritNotesRequest>({
      query: data => {
        const { id, ...params } = data
        return {
          url: prefixProxyEndpoint(`/v1/spirits/${id}/notes`),
          method: 'GET',
          params: {
            ...params,
            perPage: 10,
          },
        }
      },
      providesTags: ['spirits'],
    }),
    // 刪除這支酒的品飲筆記 (id 為 note的 id)
    deleteSpiritNote: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/my-notes/${id}`),
        method: 'DELETE',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(spiritsApi.util.invalidateTags(['spirits']))
        }
      },
    }),
    // 將這支酒從 ｍy spirit 刪除
    deleteMySpirit: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/my-notes/${id}`),
        method: 'DELETE',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '已刪除品飲筆記',
              type: 'default',
              showIcon: false,
            }),
          )
          dispatch(spiritActions.deleteMySpirit(props.id))
          dispatch(spiritActions.deleteProfileUserNoteList(props.id))
          dispatch(spiritsApi.util.invalidateTags(['spirits', 'userInfo']))
        }
      },
    }),
    // 取得這支酒的所有 featured notes list
    getSpiritFeaturedNotes: build.query<any, { id: string }>({
      query: id => ({
        url: prefixProxyEndpoint(`/v1/spirits/${id}/featured-notes`),
        method: 'GET',
      }),
      providesTags: ['spirits', 'note'],
    }),
    // 取得這支酒的個人資訊 ex: 是否有收藏、自己的評論
    getSpiritPersonalInfo: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/spirits/${id}/my-data`),
        method: 'GET',
      }),
      providesTags: ['spirits', 'note'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(spiritActions.setAddRateLoading(false))
        }
      },
    }),
    // 上傳圖片至 image server 取得 path
    postMedia: build.mutation<PostMediaResponse, PostMediaRequest>({
      query: data => {
        const formData = new FormData()
        formData.append('media', data.media)

        return {
          url: prefixProxyEndpoint('/v1/media'),
          method: 'POST',
          body: formData,
        }
      },
    }),
    // 取得特定使用者 wishlist
    getUserWishlistData: build.query<
      GetUserWishlistDataResponse,
      GetUserWishlistDataRequest
    >({
      query: ({ userId, collectId, page }) => ({
        url: prefixProxyEndpoint(
          `/v1/users/${userId}/collections/${collectId}/spirits`,
        ),
        method: 'GET',
        params: {
          page,
        },
      }),
      providesTags: ['spirits', 'note'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          const payload = {
            ...result.data,
            userId: props?.userId,
          }
          dispatch(spiritActions.setProfileUserWishlistList(payload))
        }
      },
    }),
    // 使用者新增酒資料 (UGC)
    postUploadSpirits: build.mutation<void, PostUploadSpiritsRequest>({
      query: body => {
        return {
          url: prefixProxyEndpoint('/v1/spirit-applications'),
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(props, { queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
        }
      },
    }),
    // 取得使用者新增酒資料 (UGC)
    getUploadSpirits: build.query<
      GetUploadSpiritsResponse,
      GetUploadSpiritsRequest
    >({
      query: params => ({
        url: prefixProxyEndpoint('/v1/spirit-applications'),
        method: 'GET',
        params: {
          ...params,
          perPage: 20,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          dispatch(
            spiritActions.setSpiritReviewList({
              status: props.status,
              data: result?.data,
            }),
          )
        }
      },
    }),
    // 取得使用者單筆新增酒資料 (UGC)
    getSingleUploadSpirits: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/spirit-applications/${id}`),
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

spiritsApi.enhanceEndpoints({
  addTagTypes: ['spirits'],
  endpoints: {},
})

export default spiritsApi
